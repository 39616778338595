@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300&display=swap');

body {
    font-family: 'Roboto', sans-serif !important;
    
    .container-fluid {
        padding: 0 !important;
        
        .row {
            margin: 0 auto;
            display: flex;
            align-items: center;
        }

        section {
            position: relative;
            min-height: calc(100vh - (104px + 83px));
            color: #212529;
            padding-top: 50px;
            overflow: hidden;

        }
    }
}

.icon {
    display: inline-block;
    height: 44px;
    width: 44px;
    margin: 0;
    padding: 0;
}

.icon-github {
    background-image: url('./resources/images/icon-github.png');
}

.icon-facebook {
    background-image: url('./resources/images/icon-facebook.png');
}

.icon-linkedin {
    background-image: url('./resources/images/icon-linkedin.png');
}

.icon-email {
    background-image: url('./resources/images/icon-email.png');
}

@media (min-width: 992px) {
    .d-lg-block {
        display: flex !important;
    }
}

@keyframes typing {
    from { max-width: 0% }
    to { max-width: 100% }
}
  
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
}