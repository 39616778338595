footer {
    padding: 20px 0 20px 0;
    min-height: 104px;
    position: relative;
    bottom: 0;
    background-color: #505050;
    width: 100%;
    margin-top: 50px;
    
    .containerFooter {
        text-align: center;
    
        .row {
            margin-right: 0;
            margin-left: 0;
            height: auto;
    
            div {
                h4 {
                    font-size: 20px;
                    line-height: 30px;
                    color: white;
                    font-weight: 400;
                }
            }

            .footerSocial {
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: center;
                padding: 15px 0px;
                
                a {
                    padding: 0 10px;
                }
            }
        }
    }
}